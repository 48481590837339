@import "../../styles/colors.scss";

.contenidosTable {
  margin-top: 35px;
  padding-top: 35px;
  border-top: 1px solid $blue-clients;

  > h3 {
    font-size: 25px;
    color: $blue-light;
    margin-bottom: 35px;
  }

  &__search {
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto 30px;

    > h2 {
      font-size: 16px;
      color: $blue-light;
      text-align: right;
      margin-right: 10px;
      width: 150px;
    }

    > button {
      background-color: $blue-light;
      width: 120px;
      height: 35px;
      border: none;
      margin-left: 10px;
      border-radius: 10px;
      color: $withe-color;
      cursor: pointer;
      &:disabled {
        background-color: $gray-txt-form;
        cursor: default;
      }
    }
  }

  &__table {
    border-collapse: separate;
    border-spacing: 0px 7.5px;
    margin-top: 20px;
    width: 100%;

    thead {
      tr {
        th {
          background-color: #d2dce3;
          font-size: 12px;
          font-weight: 700;
          padding: 10px 10px;
          &:first-child {
            border-radius: 5px 0 0 5px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }

    tbody {
      tr {
        th {
          border-bottom: 1px solid $blue-border-table;
          border-top: 1px solid $blue-border-table;
          font-size: 12px;
          font-weight: 400;
          padding: 10px 10px;

          &:first-child {
            border-left: 1px solid $blue-border-table;
            border-radius: 5px 0 0 5px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
            border-right: 1px solid $blue-border-table;
          }

          > button {
            background-color: $withe-color;
            border-radius: 10px;
            border: 1px solid #e92828;
            color: #e92828;
            cursor: pointer;
            outline: none;
            padding: 5px 15px;

            &:hover {
              background-color: #e9282810;
            }
          }
        }
      }
    }
  }
}
