@import "../../styles/colors.scss";

.ModalDelete {
  background: linear-gradient(
    178.35deg,
    rgba(233, 40, 40, 0.75) 18.85%,
    rgba(233, 40, 40, 0.77) 80.42%,
    rgba(233, 40, 40, 0.2) 142%
  );
  border-radius: 20px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  color: $withe-color;
  left: 50%;
  outline: none;
  padding: 75px 54px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 560px;

  > h2 {
    font-weight: 700;
    font-size: 30px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid $withe-color;
  }

  > h4 {
    font-weight: 700;
    font-size: 20px;
  }

  > div {
    margin-top: 30px;
    > button {
        background: #E92828;
        border-radius: 30px;
        border: none;
        color: $withe-color;
        cursor: pointer;
        font-size: 20px;
        margin-right: 15px;
        padding: 10px 30px;
        &:last-child {
            background-color: $withe-color;
            color: #E92828;
        }
    }
  }
}
