@import "../../styles/colors.scss";

.stadistic {
  background: linear-gradient(
    167.31deg,
    rgba(255, 255, 255, 0.64) -22.28%,
    rgba(255, 255, 255, 0.51) 40.15%,
    rgba(255, 255, 255, 0.23) 90.81%
  );
  backdrop-filter: blur(40px);
  border-radius: 20px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  color: $blue-light;
  margin: 10px;
}

.center {
  text-align: center;
}

.left {
  display: flex;
  align-items: center;
  width: 300px;
  h2 {
    padding-right: 40px;
    margin-right: 30px;
    border-right: 1px solid $blue-light;
  }
}
