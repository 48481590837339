@import "../../styles/colors.scss";

.adminSummary {
  width: 98%;
  min-height: 100%;
  margin: 0 auto;

  &__content {
    background: linear-gradient(
      167.31deg,
      rgba(255, 255, 255, 0.64) -22.28%,
      rgba(255, 255, 255, 0.51) 40.15%,
      rgba(255, 255, 255, 0.23) 90.81%
    );
    border-radius: 20px;
    box-shadow: 1px 10px 49px -1px rgba(62, 62, 62, 0.15);
    padding: 35px;
    width: 100%;
    backdrop-filter: blur(40px);
    > h2 {
      color: $blue-light;
      margin-bottom: 25px;
    }
    &Generales {
      width: 100%;
      display: flex;
      align-items: center;

      &Rigth {
        width: 38%;
        margin-right: 1%;
        padding-right: 1%;
        border-right: 1px solid $blue-light;
      }

      &Left {
        width: 60%;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (min-width: 1220px) {
  .adminSummary {
    width: 90%;
  }
}
