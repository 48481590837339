@import "../../styles/colors.scss";

.userLayoud {
  background-color: $blue-bg;
  background-image: url("../../assets/img/BG-home.svg");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 117vh;
  width: 100%;
  zoom: 85%;

  &__Content {
    padding-top: 25px;
    width: 94%;
    margin: 0 auto;
    background-color: $withe-color;
    border-radius: 40px 40px 0 0;
  }
}

@media screen and (min-width: 1220px) {
  .userLayoud {
    display: flex;
    align-items: flex-end;

    &__Content {
      width: 80%;
      border-radius: 40px 0 0 0;
      height: 105vh;
      overflow-y: scroll;
    }
  }
}

@media screen and (min-width: 1920px) {
  .userLayoud {
    &__Content {
      width: 85%;
    }
  }
}
