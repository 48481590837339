@import "../../styles/colors.scss";

.adminNav {
  background-color: $blue-light;
  border-radius: 20px 20px 0 0;
  color: $withe-color;
  margin: 0 auto;
  padding: 15px 30px;
  position: relative;
  transition: all ease 0.4s;
  width: 98%;
  &__welcome {
    margin-bottom: 25px;
    p {
      font-size: 16px;
      margin-bottom: 10px;
    }
    h4 {
      font-weight: 700;
      font-size: 18px;
    }
  }

  &__menu {
    display: flex;
    &Button {
      align-items: center;
      color: white;
      cursor: pointer;
      display: flex;
      font-size: 20px;
      font-weight: 700;
      margin-right: 30px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &Icon {
        margin-right: 15px;
      }
    }
    div {
      align-items: center;
      display: flex;

      h5 {
        align-items: center;
        color: white;
        cursor: pointer;
        display: flex;
        font-size: 20px;
        font-weight: 700;
        margin-right: 15px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      ul {
        display: flex;
        list-style: none;
        margin-right: 30px;
        li {
          a {
            color: $withe-color;
            margin-right: 10px;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &__logOut {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 30px;
    top: 15px;

    a {
      align-items: center;
      color: $withe-color;
      display: flex;
      flex-direction: row-reverse;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    span {
      font-size: 10px;
    }
  }
}

@media screen and (min-width: 1220px) {
  .adminNav {
    align-items: flex-start;
    border-radius: 0 20px 0 0;
    display: flex;
    flex-direction: column;
    height: 117vh;
    padding: 100px 30px;
    width: 15%;
    &__welcome {
      margin-bottom: 70%;
      text-align: center;
      width: 100%;
    }
    &__menu {
      flex-direction: column;

      &Button {
        margin-right: 0;
        margin-bottom: 30px;
      }
      div {
        flex-direction: column;
        margin-bottom: 30px;

        h5 {
          margin-right: 0;
        }

        ul {
          flex-direction: column;
          margin: 15px 0 0 10px;
          li {
            margin-bottom: 10px;
            a {
              margin-right: 0;
            }
          }
        }
      }
    }

    &__logOut {
      right: auto;
      left: 30px;
      top: auto;
      bottom: 70px;
      align-items: flex-start;
      a {
        flex-direction: row;
      }
    }
  }
}
