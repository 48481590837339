@import "../../styles/colors.scss";

.CodeTag {
  align-items: center;
  border-radius: 10px;
  border: 1px solid $blue-light-medium;
  box-shadow: -4px 5px 7px rgba(210, 220, 227, 0.4);
  color: $blue-light-medium;
  display: flex;
  font-size: 30px;
  font-weight: 700;
  height: 90px;
  justify-content: flex-end;
  width: 32%;

  > h5 {
    width: 55%;
    text-align: center;
  }

  > div {
    align-items: center;
    background-color: $blue-light-medium;
    border-radius: 0 10px 10px 0;
    color: $withe-color;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    padding: 10px;
    width: 45%;

    > p {
        margin-left: 10px;
    }
  }
}
