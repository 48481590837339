@import "../../styles/colors.scss";

.Home {
  width: 100%;
  header {
    display: flex;
    justify-content: space-around;
    padding: 30px;
    box-shadow: 0px 17px 20px -13px rgba(10, 143, 236, 0.15);
    align-items: center;
    flex-wrap: wrap;
    .NameCard {
      border-radius: 30px;
      position: relative;
      width: 480px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 16px;
      background-color: #e8f9fd;
      color: #009dff;
      h2 {
        width: 60%;
        text-align: left;
        border-bottom: 1px solid $blue-light;
        font-size: 3rem;
        margin: 0 0 20px 0;
      }
      p {
        font-size: 1rem;
        margin: 0;
      }
      .Welcome {
        font-size: 2rem;
      }
    }
    .Imagen {
      position: absolute;
      right: -46px;
      width: 180px;
      bottom: -7px;
    }
    a {
      text-decoration: none;
      color: white;
      width: 400px;
      margin: 20px;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      .RecentContent {
        border-radius: 30px;
        widows: 100%;
        padding: 40px;
        background-color: $blue-dark-extra;
        color: white;
        justify-content: center;
        align-items: center;

        p {
          font-size: 20px;
          font-weight: 300;
          font-style: normal;
          border-bottom: 1px solid white;
          text-align: justify;
          align-items: center;
          width: 90%;
          margin: 10px;
        }
        &:hover {
          background-color: $blue-dark-medium;
        }
        h3 {
          text-align: center;
          font-size: 25px;
        }
      }
    }
  }
  section {
    padding: 30px;
    .Filtros {
      display: flex;
      justify-content: space-between;

      section {
        font-weight: 700;
        font-size: 35px;
        color: darkblue;
        padding: 15px;
      }
    }
    .Ordenar {
      border-left: 1px solid rgba(1, 1, 148, 0.25);
      display: flex;
      justify-content: space-evenly;
      padding-left: 30px;
      width: 45%;
      flex-wrap: wrap;
      p {
        color: $blue-dark;
        font-weight: 400;
        font-size: 40px;
        cursor: default;
      }
    }
  }
  .Barra-Contenedor {
    display: flex;
    justify-content: space-around;
    padding-left: 30px;
    width: 45%;
    flex-wrap: wrap;
    border-left: 1px solid rgba(1, 1, 148, 0.25);
  }
  /* Estilos para la barra de búsqueda */
  .Barra-Busqueda {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }

  .Barra-Busqueda input[type="text"] {
    flex: 1;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 25px;
    color: $gray-text;
  }

  .Barra-Busqueda button {
    background-color: $blue-light;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 25px;
  }

  /* Efectos de hover para el botón */
  .Barra-Busqueda button:hover {
    background-color: $blue-dark;
  }

  .X-boton {
    background-color: white !important;
    border: none !important;
    color: $gray-dark !important;
    font-weight: 400;
    outline: none;
    margin-right: 20px;
  }

  .X-boton:hover {
    font-weight: 500;
    cursor: pointer;
    background-color: $gray-medium !important;
    border-radius: 50%;
  }

  .Content-Card {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    min-height: 300px;

    > h4 {
      color: $blue-dark;
      margin-top: 130px;
      font-size: 25px;
    }
  }
}
