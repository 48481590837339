@import "../../styles/colors.scss";

.ContentDownloads {
  width: 98%;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__content {
    background-color: $withe-color;
    border-radius: 20px;
    box-shadow: 1px 10px 49px -1px rgba(62, 62, 62, 0.15);
    margin: 25px 0 35px;
    padding: 35px;
    width: 100%;

    > h1 {
      color: $blue-light;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    &Detail {
      width: 100%;
      display: flex;
      align-items: flex-end;

      &Pdf {
        border-top: 1px solid $gray-medium;
        min-height: 500px;
        padding-top: 25px;
        width: 50%;
      }

      &Info {
        overflow-y: scroll;
        padding: 0 60px 0 35px;
        width: 50%;

        > h3 {
          color: $blue-light;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        > p {
          font-size: 14px;
          margin-bottom: 20px;

          > span {
            color: $blue-light;
            font-size: 18px;
            margin-right: 10px;
          }
        }

        &List {
          padding-top: 20px;
          > h2 {
            border-bottom: 1px solid $gray-medium;
            color: $blue-light;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 15px;
            padding-bottom: 10px;
          }

          > button {
            align-items: center;
            background-color: $purple;
            border-radius: 40px;
            border: none;
            color: $withe-color;
            cursor: pointer;
            display: flex;
            font-size: 14px;
            justify-content: space-evenly;
            margin-bottom: 20px;
            padding: 10px 20px;

            > span {
              margin-right: 5px;
            }
          }

          > ul {
            width: 100%;

            > li {
              border-bottom: 0.5px solid $blue-light-medium;
              display: flex;
              font-size: 14px;
              justify-content: space-between;
              padding: 15px 0;

              &:last-child {
                border: none;
                padding-bottom: 0;
              }

              > p {
                > span {
                  color: $blue-light;
                  font-weight: 600;
                }
              }

              > button {
                background-color: $withe-color;
                border-radius: 10px;
                border: 1px solid #E92828;
                color: #E92828;
                cursor: pointer;
                outline: none;
                padding: 5px 15px;

                &:hover {
                    background-color: #e9282810;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1220px) {
  .ContentDownloads {
    width: 90%;
  }
}
