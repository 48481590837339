@import "../../styles/colors.scss";

.search {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  &__filters {
    width: 75%;

    &__input {
      display: flex;
      width: 100%;
    }

    &__icon {
      align-items: center;
      background-color: $blue-clients;
      border-radius: 40px 0px 0px 40px;
      color: $withe-color;
      display: flex;
      font-size: 14px;
      height: 35px;
      justify-content: center;
      width: 45px;
    }

    &__date {
      align-items: center;
      color: $blue-light-medium;
      cursor: pointer;
      display: flex;
      margin-top: 15px;
      &Icon {
        font-size: 18px;
      }
      > label {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        margin-right: 30px;
        > input {
          background-color: #f8f8f8;
          border-radius: 5px;
          border: 1px solid #d2dce3;
          height: 30px;
          margin-left: 10px;
          outline: none;
          padding: 10px;
          width: 180px;
        }
      }
    }
  }

  &__button {
    background-color: $purple;
    border-radius: 40px;
    border: none;
    color: $withe-color;
    cursor: pointer;
    font-size: 14px;
    height: 35px;
    padding: 0 20px;
  }
}
