@import "../../styles/colors.scss";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.CodeForm {
  width: 100%;
  text-align: center;
  input {
    background: #f8f8f8;
    border-radius: 5px;
    border: 1px solid #d2dce3;
    color: $gray-txt-form;
    font-size: 30px;
    outline: none;
    padding: 10px;
    text-align: center;

    &:disabled {
      background-color: #fce1e190;
      color: $gray-light;
    }
  }

  > input {
    width: 100%;
  }

  > p {
    color: $gray-txt-form;
    font-weight: 300;
    font-size: 12px;
    margin: 10px 0;
  }

  > div {
    display: flex;
    justify-content: space-between;

    > input {
      width: 100%
    }
  }

  &__days {
    width: 100%;
     > input {
      width: 70% !important;
      font-size: 18px;
    }

    > button {
      cursor: pointer;
      background: $purple;
      border-radius: 5px;
      padding: 13px 0;
      transition: all ease 0.4s;
      color: $withe-color;
      font-weight: 500;
      font-size: 15px;
      height: 57px;
      border: none;
      outline: none;
      width: 28%;
      &:hover {
        background-color: $blue-dark;
      }
      &:disabled {
        background: $gray-txt-form;
      }
    }
  }
}
