@import "../../styles/colors.scss";

.footer {
  background-color: $withe-color;
  height: 60px;
  width: 100%;

  & div {
    border-top: 2px solid $gray-dark;
    color: $gray-dark;
    font-size: 12px;
    font-weight: 400;
    margin: 0 auto;
    padding: 25px 0;
    text-align: center;
    width: 60%;
  }
}
