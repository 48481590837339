@import "../../styles/colors.scss";

.IframeContainerContent {
  width: 100%;
  height: 72vh;
  position: relative;
  overflow-y: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::-webkit-scrollbar {
    width: 0;
  }

  > a {
    background-color: $withe-color;
    border-radius: 20px;
    box-shadow: -2px 3px 8px 5px #0A8FEC30;
    color: $blue-light;
    font-weight: 700;
    left: 20px;
    padding: 15px 25px;
    position: absolute;
    text-decoration: none;
    top: 20px;
    transition: all ease .3s;
    z-index: 999;

    &:hover {
        color: $withe-color;
        background-color: $blue-light;
    }
  }

  > .Iframe2 {
    width: 70%;
    height: 100%;
  }
}
