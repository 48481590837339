@import "../../styles/colors.scss";

.ModalInstitucion {
  background: linear-gradient(
    167.31deg,
    rgba(255, 255, 255, 0.94) -22.28%,
    rgba(255, 255, 255, 0.81) 40.15%,
    rgba(255, 255, 255, 0.53) 90.81%
  );
  border-radius: 20px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  left: 50%;
  outline: none;
  padding: 130px 180px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 900px;

  > h2 {
    color: $blue-light;
    font-weight: 700;
    font-size: 30px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $gray-medium;
  }

  label {
    font-weight: 700;
    font-size: 15px;
    line-height: 150%;
    color: $blue-light;
    display: flex;
    flex-direction: column;
  }

  &__place {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    > label {
      width: 49%;

      > input {
        background-color: #f8f8f8;
        border-radius: 5px;
        border: 1px solid #d2dce3;
        height: 35px;
        outline: none;
        padding: 10px;
      }
    }
  }

  &__institucion {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    > label {
        margin: 0;
    }
    > input {
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #d2dce3;
      height: 15px;
      margin-left: 10px;
      width: 15px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    > button {
      background: $blue-light;
      border-radius: 30px;
      border: none;
      color: $withe-color;
      cursor: pointer;
      font-size: 20px;
      margin-right: 15px;
      padding: 10px 80px;
      &:last-child {
        background-color: $withe-color;
        border: 1px solid $blue-light;
        color: $blue-light;
        margin: 0;
        padding: 10px 30px;
      }
    }
  }
}
