@import "../../styles/colors.scss";

.Commit {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  &:hover{
    background-color: $gray-light;
  }
  .CommitUser {
    color: $withe-color;
    display: flex;
    justify-content: space-between;
    &__user {
      &Name {
        border-radius: 100%;
        border: 3px solid $blue-light;
        height: 90px;
        margin-bottom: 10px;
        padding: 7px;
        width: 90px;
      }
      &NameDiv {
        align-items: center;
        background-color: $yellow;
        border-radius: 100%;
        color: $withe-color;
        display: flex;
        font-size: 40px;
        font-weight: 700;
        height: 100%;
        justify-content: center;
        width: 100%;
      }
    }
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    div {
      margin-left: 80px;
      h4 {
        span {
          font-weight: lighter;
          font-style: italic;
        }
      }
    }
    p {
      padding: 20px 20px 0 80px;
      color: $gray-dark;
      font-weight: 300;
      font-size: 20px;
      text-align: justify;
    }
  }
  &__Date {
    color: $gray-dark;
    font-size: 15px;
    border-top: 1px solid $gray-dark;
    margin-top: 20px;
    justify-content:left;
  }
}
