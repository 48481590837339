@import "../../styles/colors.scss";

.ModalRecuperarPass {
  background: linear-gradient(
    167.31deg,
    rgba(255, 255, 255, 0.94) -22.28%,
    rgba(255, 255, 255, 0.81) 40.15%,
    rgba(255, 255, 255, 0.94) 90.81%
  );
  border-radius: 20px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  left: 50%;
  outline: none;
  padding: 20px 50px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  display: flex;
  flex-direction: column;
  > h2 {
    color: $blue-light;
    font-weight: 700;
    font-size: 30px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $gray-medium;
  }
  .ModalPass__buttons{
    display: flex;
    justify-content: center;
    margin: 20px 0;
    > button {
      background: $blue-light;
      border-radius: 30px;
      border: none;
      color: $withe-color;
      cursor: pointer;
      font-size: 20px;
      margin-right: 15px;
      padding: 10px 40px;
      &:last-child {
        background-color: $withe-color;
        border: 1px solid $blue-light;
        color: $blue-light;
        margin: 0;
        padding: 10px 30px;
      }
    }
  }
}
