@import "../../styles/colors.scss";

.cargando {
  font-size: 22px;
  color: $blue-light;
  text-align: center;
  margin-top: 35px;
}
.MailForm {
  width: 100%;

  > p {
    color: $blue-light;
    font-size: 14px;
    margin-bottom: 5px;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    width: 100%;

    > input {
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #d2dce3;
      font-size: 14px;
      height: 30px;
      margin-right: 10px;
      outline: none;
      padding: 10px;
      width: 80%;
      &:disabled {
        background-color: #fce1e190;
      }
    }

    > button {
      background: #00ccff;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0 20px;
      color: $withe-color;
      font-size: 15px;
      font-weight: 500;
      &:disabled {
        background: $gray-txt-form;
      }
    }
  }

  > input {
    background: #f8f8f8;
    border-radius: 5px;
    border: 1px solid #d2dce3;
    font-size: 14px;
    height: 30px;
    margin-bottom: 10px;
    outline: none;
    padding: 10px;
    width: 100%;
    &:disabled {
      background-color: #fce1e190;
    }
  }

  > textarea {
    background: #f8f8f8;
    border-radius: 5px;
    border: 1px solid #d2dce3;
    height: 155px;
    outline: none;
    resize: none;
    width: 100%;
    padding: 15px;
    &:disabled {
      background-color: #fce1e190;
    }
  }
}
