@import "../../styles/colors.scss";

.IframeContainer {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-y: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0990f0a6;

    &::-webkit-scrollbar {
        width: 0;
    }

    >a {
        background-color: #fff;
        border-radius: 15px;
        box-shadow: -2px 3px 8px 5px rgba(10, 143, 236, 0.1882352941);
        color: #0A8FEC;
        font-weight: 700;
        left: 5px;
        padding: 8px 13px;
        position: absolute;
        text-decoration: none;
        top: 25px;
        transition: all ease 0.3s;
        z-index: 999;
        font-size: 20px;

        &:hover {
            color: $withe-color;
            background-color: $blue-light;
        }

        .Icon {
            margin-right: 7px;

        }
    }

    >iframe {
        width: 95%;
        height: 97vh;
        border-radius: 15px;
    }
}
