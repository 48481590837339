@import "./styles/colors.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background: #00c8ff60;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #00c8ff;
  }
}

.sized-container-div {
  margin: auto;
  margin-top: 1%;
  height: 800px;
  width: 80%;
  border: 1px solid #dadada;
}

.App {
  min-width: 600px;
}
