@import "../../styles/colors.scss";

.signIn {
  display: flex;
  height: 100%;
  width: 100%;

  &__banner {
    width: 45%;
    display: none;
    justify-content: flex-end;
  }
  &__formContainer {
    align-items: center;
    background-image: url("../../assets/img/BG-SingIn.svg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 150px 0 50px;
    width: 100%;
    & form {
      align-content: space-between;
      background-color: $withe-color;
      border-radius: 20px;
      box-shadow: -1px 7px 22px 3px rgba(10, 143, 236, 0.15);
      margin-bottom: 20px;
      padding: 30px 50px;
      width: 585px;

      & h1 {
        color: $blue-light;
        font-size: 30px;
        font-weight: 700;
        line-height: 150%;
      }

      h6 {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $blue-light;
        padding-bottom: 5px;
        border-bottom: 1px solid $gray-medium;
        margin: 10px 0 15px;
      }

      label {
        font-weight: 700;
        font-size: 15px;
        line-height: 150%;
        color: $blue-light;
        display: flex;
        flex-direction: column;
      }
    }

    &Towcol {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &LabelMid {
      width: 49%;
    }

    &Label {
      &Deparment {
        width: 60%;
      }
      &City {
        width: 37%;
      }
    }

    &Input {
      height: 35px;
      margin: 10px 0 20px;

      &Mid {
        width: 97%;
      }

      &Large {
        width: 100%;
      }

      &Select {
        margin: 10px 0 20px !important;
        width: 100%;
      }
    }

    &Onecol {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      &Select {
        margin: 15px 0 15px 15px !important;
        width: 85%;
      }
      &Input {
        margin: 15px 0 15px 15px !important;
        width: 80%;
        &Codigo {
          height: 35px;
          margin: 15px 0 15px 15px !important;
          width: 80%;
        }
      }
    }

    p {
      color: $gray-dark;
      font-size: 10px;
      font-weight: 700;
      line-height: 150%;
      text-align: center;
    }

    &Button {
      background-color: $blue-light;
      border-radius: 30px;
      border: none;
      color: $withe-color;
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      height: 45px;
      transition: all ease 0.4s;
      width: 585px;
      &:hover {
        background-color: $blue-dark;
      }
    }
    .signIn__formContainerButtonDos {
      margin-top: 15px;
      background-color: $withe-color;
      border-radius: 30px;
      border: 0.2px solid $blue-light;
      color: $blue-light;
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      height: 45px;
      transition: all ease 0.4s;
      width: 585px;
      &:hover {
        border: 2px solid $blue-light;
      }
    }
  }
}

@media screen and (min-width: 1220px) {
  .signIn {
    display: flex;

    &__banner {
      display: flex;
    }

    &__formContainer {
      padding: 50px 0;
      width: 55%;
    }
  }
}
