@import "../../styles/colors.scss";

header {
  width: 100%;
  box-shadow: 0px 17px 20px -13px rgba(10, 143, 236, 0.15);
  padding: 50px;
  height: auto;
  padding-bottom: 0;
  .ContentName {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    color: $blue-dark-medium;
    div {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    .Icon {
      font-size: 45px;
      margin-right: 30px;
    }
    h2 {
      border-bottom: 1.5px solid $blue-dark;
      width: 80%;
      font-size: 35px;
    }
    .Descripcion {
      margin: 30px 0;
      font-weight: 400;
      color: $gray-text;
      font-size: 18px;
      width: 90%;
      word-wrap: break-word; /* Permite romper palabras largas */
      overflow-wrap: break-word; /* Mejora el control de saltos de línea */
      text-align: left; /* Alineación izquierda */
      line-height: 1.5; /* Espacio entre líneas adecuado */
      text-align: justify; /* Alineación justificada */
      .Boton {
        color: $blue-dark;
        background-color: white;
        padding: 5px 10px 5px 10px;
        border-radius: 15px;
        border: 1px solid $blue-dark;
        font-size: 15px;
        margin-top: 20px;
      }
      :hover {
        cursor: pointer;
        color: $gray-light;
        background-color: $blue-dark-medium;
        font-weight: 400;
      }
    }
  }
}

.PDF-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Iframe-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.contentMaxi {
  display: flex;
  width: 100%;
  justify-content: center;
  .maxiLink {
    background-color: $withe-color;
    border-radius: 20px;
    box-shadow: -2px 3px 8px 5px #0a8fec30;
    color: $blue-light;
    font-weight: 700;
    padding: 15px 25px;
    text-decoration: none;
    transition: all ease 0.3s;
    margin-top: 30px;
    border: none;
    &:hover {
      color: $withe-color;
      background-color: $blue-light;
      cursor: pointer;
    }
  }
}

.ContentDescargable {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: $blue-dark-extra;
  padding: 30px;
  font-size: 25px;
  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .Icon {
    font-size: 45px;
    margin-right: 30px;
  }
  h2 {
    border-bottom: 2px solid $blue-dark-extra;
    padding-bottom: 15px;
  }
  .DownloadCard {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;

    a {
      text-decoration: none;
    }
  }
}

.ContentComments {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: $blue-bg;
  box-shadow: 0px 17px 20px -13px rgba(10, 143, 236, 0.15);
  padding: 30px;
  font-size: 25px;
  .IconDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    transition: all 0.8s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
      cursor: pointer;
    }
  }
  .Icon {
    font-size: 45px;
    margin-right: 30px;
  }
  h2 {
    padding-bottom: 15px;
    border-bottom: 2px solid $blue-bg;
  }
  .IconArrow {
    font-size: 45px;
    margin: 0 30px 0 30px;
  }
  .Commit_1 {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    .secctionMore {
      margin: 10px;
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        color: $blue-light-medium;
      }
      .IconMore {
        padding: 0 10px 0 10px;
      }
      .IconArrow {
        font-size: 20px;
        margin: 0 40px 0 40px;
      }
    }
    .ComitForm {
      width: 100%;
      border: 1.5px solid $blue-bg;
      padding: 30px;
      border-radius: 0px 20px 20px 20px;
      > p {
        color: $blue-light;
        font-size: 16px;
        margin: 15px 0 15px 0;
        font-weight: 700;
      }
      > div {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        width: 100%;

        > input {
          background: #f8f8f8;
          border-radius: 5px;
          border: 1px solid #d2dce3;
          font-size: 14px;
          height: 30px;
          margin-right: 10px;
          outline: none;
          padding: 10px;
          width: 100%;
        }
      }
      > button {
        background: $blue-light;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0 20px;
        color: $withe-color;
        font-size: 15px;
        font-weight: 500;
      }
      > textarea {
        background: #f8f8f8;
        border-radius: 5px;
        border: 1px solid #d2dce3;
        height: 155px;
        outline: none;
        resize: none;
        width: 100%;
        padding: 15px;
      }
    }
  }
}
