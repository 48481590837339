@import "../../styles/colors.scss";

.codesGenerator {
  width: 98%;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__content {
    background-color: $withe-color;
    border-radius: 20px;
    box-shadow: 1px 10px 49px -1px rgba(62, 62, 62, 0.15);
    margin: 25px 0 35px;
    padding: 35px;
    width: 100%;

    &Search {
      margin-bottom: 30px;
      width: 100%;
    }

    &Forms {
      width: 100%;
      > h1 {
        border-bottom: 1px solid $blue-light;
        color: $blue-light;
        font-size: 25px;
        font-weight: 700;
        padding-bottom: 10px;
      }

      > div {
        display: flex;
        padding: 60px 40px;
      }

      &Codes {
        border-right: 1px solid $gray-medium;
        margin-right: 25px;
        padding-right: 25px;
        width: 35%;
      }

      &Mail {
        width: 65%;
        &Text {
          color: $blue-light;
          font-size: 25px;
          text-align: center;
          margin-top: 100px;
        }
      }
    }
    &CodesList {
      margin-bottom: 40px;
      > h2 {
        color: $blue-light;
        font-size: 18px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        > button {
          background-color: $blue-light;
          border-radius: 5px;
          border: none;
          color: $withe-color;
          cursor: pointer;
          margin-left: 10px;
          padding: 5px 10px;
          &:hover {
            background-color: $blue-clients;
          }
          > svg {
            margin-right: 5px;
          }
        }
      }
      > ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;

        > li {
          margin: 5px 0;
          width: 33%;
          > span {
            color: $blue-light;
            margin-right: 10px;
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
    }
    &Stadistics {
      width: 100%;
      > h4 {
        font-size: 14px;
        margin-bottom: 5px;
        color: $blue-light;
        > span {
          color: $gray-txt-form;
          font-weight: 300;
          font-size: 12px;
        }
      }

      > h2 {
        border-bottom: 1px solid $blue-light;
        color: $blue-light;
        font-size: 18px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        > span {
          color: $gray-txt-form;
          font-weight: 300;
          font-size: 12px;
        }
      }

      > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (min-width: 1220px) {
  .codesGenerator {
    width: 90%;
  }
}
