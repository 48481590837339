@import "../../styles/colors.scss";

.ContentCard {
  width: 300px;
  height: 345px;
  display: flex;
  margin: 30px;
  box-shadow: -2px 3px 8px 5px rgba(1, 1, 148, 0.03);
  border-radius: 25px;
  flex-direction: column;
  text-decoration: none;
  color: $black-color;

  .Content {
    display: flex;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 245px;
    background-color: #ffb627;
    position: relative;
    padding: 5px;
    justify-content: center;

    .ImageContainer {
      height: 100%; // Establece la altura del contenedor de la imagen al 100% del padre
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
    img {
      object-fit: cover; // Ajustar la imagen al contenedor manteniendo la relación de aspecto
      max-width: 70%; // Asegurar que la imagen no se desborde del contenedor en ancho
      max-height: 100%; // Asegurar que la imagen no se desborde del contenedor en altura
    }
    .Icon {
      font-size: 30px;
      color: white;
      position: absolute;
      bottom: 15px;
      left: 15px;
    }
  }
  .ContentBlue {
    display: flex;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 245px;
    background-color: #1a1aa5;
    position: relative;
    padding: 5px;
    justify-content: center;
    .Icon {
      font-size: 30px;
      color: white;
      position: absolute;
      bottom: 15px;
      left: 10px;
    }
  }
  .Titulo {
    font-weight: 500;
    font-size: 16px;
    line-height: 149.4%;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
}
.ContentCard:hover {
  cursor: pointer;
  box-shadow: -2px 3px 8px 5px rgba(1, 1, 148, 0.05);
  .Content {
    background-color: #fca905;
  }
  .ContentBlue {
    background-color: $blue-dark;
  }
}
