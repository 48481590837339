@import "../../styles/colors.scss";

.Card {
  // border: dashed 1px blue;
  margin: 20px;
  height: 100px;
  width: 350px;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  box-shadow: -2px 3px 8px 5px rgba(1, 1, 148, 0.03);
  border-radius: 15px;
  align-items: center;
  border: none;
  background-color: $withe-color;
  &:hover{
    .DownloadBt{
      background-color: $blue-dark-extra;      
    }
    cursor: pointer;    
  }
  h3 {
    width: 85%;
    padding: 15px;
    color: $blue-dark-extra;
    font-weight: 700;
    font-size: 20px;
  }
  .DownloadBt {
    background-color: $blue-dark-medium;
    width: 15%;
    height: 100%;
    right: 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 0;
    margin: auto;
    padding-left: 8px;
    align-items: center;
    .Icon {
      color: white;
      font-size: 30px;
    }
    &:hover {
      background-color: $blue-dark-extra;
      cursor: pointer;
    }
  }
  &:hover {
    box-shadow: -2px 3px 8px 5px rgba(1, 1, 148, 0.08);
  }
}
