$blue-light-extra: #72FFFF;
$blue-light-medium: #00c6ff;
$blue-light: #0A8FEC;
$blue-dark-medium: #00009C;
$blue-dark: #010194;
$blue-dark-extra: #5800FF; 
$blue-bg: #009dff;
$blue-clients: #00CCFF;
$blue-border-table: #00ccff40;

$purple: #5800FF;

$gray-light: #F8F8F8;
$gray-medium: #D2DCE3;
$gray-dark: #3e3e3eb5;
$gray-txt-form: #747474;
$gray-text: #373737;

$yellow: #FFB627;

$withe-color: #fff;
$black-color: #000;
