@import "../../styles/colors.scss";

.login {
  display: flex;
  height: 100%;
  width: 100%;

  &__banner {
    width: 45%;
    display: none;
    justify-content: flex-end;
  }

  &__container {
    align-items: center;
    background-image: url("../../assets/img/BG-Login.svg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100%;

    h1 {
      color: $withe-color;
      font-size: 45px;
      font-weight: 700;
      margin-bottom: 120px;
    }

    form {
      width: 450px;
    }

    &Input {
      align-items: center;
      border-bottom: 1px solid $withe-color;
      display: flex;
      margin-bottom: 20px;
      padding: 5px 0;
      width: 100%;
      &Icon {
        color: $withe-color;
        font-size: 30px;
        margin-right: 30px;
      }
    }

    &Recover {
      color: $withe-color;
      display: block;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 65px;
      text-decoration: none;
      width: 100%;
      &:hover {
        text-decoration: underline;
      }
    }
    &Buttons {
      display: flex;
      justify-content: space-between;
      &Log {
        background-color: $blue-dark;
        border-radius: 30px;
        border: 2px solid $blue-dark-medium;
        color: $withe-color;
        cursor: pointer;
        font-size: 20px;
        font-weight: 700;
        height: 60px;
        outline: none;
        transition: all ease 0.4s;
        width: 48%;
        &:hover {
          border-color: $blue-light;
          background-color: $blue-dark-extra;
        }
      }

      &Create {
        display: inline-block;
        background-color: #ffffff00;
        border-radius: 30px;
        border: 2px solid $withe-color;
        cursor: pointer;
        font-size: 20px;
        font-weight: 700;
        height: 60px;
        outline: none;
        transition: all ease 0.4s;
        width: 48%;
        &:hover {
          border-color: $withe-color;
          background-color: #ffffff60;
        }
        & a {
          color: $withe-color;
          text-decoration: none;
        }
      }
    }
  }
}


@media screen and (min-width: 1220px) {
  .login {
    display: flex;

    &__banner {
      display: flex;
    }

    &__container {
      width: 55%;
    }
  }
}
