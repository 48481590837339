@import "../../styles/colors.scss";

.bannerWelcome {
    
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  max-width: 600px;
  width: 80%;

  h2 {
    border-bottom: 3px solid $blue-light;
    color: $blue-light;
    font-size: 34px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 35px;
    padding-bottom: 35px;
  }

  p {
    color: $blue-light;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
  }

  picture {
    margin-top: 70px;
    width: 100%;
    img {
      width: 100%;
    }
  }
}
