@import "../../styles/colors.scss";

.tableUsers {
  border-collapse: separate;
  border-spacing: 0px 7.5px;
  margin-top: 20px;
  width: 100%;

  thead {
    tr {
      th {
        background-color: #d2dce3;
        font-size: 12px;
        font-weight: 700;
        padding: 10px 10px;
        &:first-child {
          border-radius: 5px 0 0 5px;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }

  tbody {
    tr {
      th {
        border-bottom: 1px solid $blue-border-table;
        border-top: 1px solid $blue-border-table;
        font-size: 12px;
        font-weight: 400;
        padding: 10px 10px;

        &:first-child {
          border-left: 1px solid $blue-border-table;
          border-radius: 5px 0 0 5px;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
          border-right: 1px solid $blue-border-table;
        }
      }
    }
  }
}

#actions {
  background-color: $blue-clients;
  color: $withe-color;
}

#actionsBody {
    display: flex;
    justify-content: space-evenly;
    font-size: 16px;
}

#actionsBodyEdit {
    color: #00009C;
    cursor: pointer;
}

#actionsBodyDelete {
    color: #E92828;
    cursor: pointer;
}

#actionsBodyActive {
    color: #5800FF;
    cursor: pointer;
}

#actionsBodyLink {
    color: #009DFF;
    cursor: pointer;
}