@import "../../styles/colors.scss";

.error {
    align-items: center;
    background-image: url('../../assets/img/BG-SingIn.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100%;
    h1 {
        font-size: 65px;
        color: $blue-light;
    }
    h4 {
        font-size: 27px;
        color: $blue-bg;
    }
    a {
        color: $blue-light;
        text-decoration: none;
        &:visited {
            color: $blue-light;
            text-decoration: none;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}