@import "../../styles/colors.scss";

.log {
  width: 165px;
  margin-top: 20px;
}

.users {
  align-items: center;
  background-color: $withe-color;
  border-radius: 40px 0 0 40px;
  display: flex;
  height: 65px;
  justify-content: flex-end;
  padding-right: 20px;
  position: absolute;
  right: 0;
  top: 15px;
  width: 145px;
  z-index: 999;
  img {
    width: 85px;
  }
}

.admin {
  position: absolute;
  top: 20px;
  left: 30px;
  width: 100px;
  z-index: 999;
  img {
    width: 100%;
  }
}
