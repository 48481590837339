@import "../../styles/colors.scss";

.adminInstitucion {
  width: 98%;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__content {
    background-color: $withe-color;
    border-radius: 20px;
    box-shadow: 1px 10px 49px -1px rgba(62, 62, 62, 0.15);
    margin: 25px 0 35px;
    padding: 35px;
    width: 100%;
  }
}

@media screen and (min-width: 1220px) {
  .adminInstitucion {
    width: 90%;
  }
}