@import "../../styles/colors.scss";

.adminLayoud {
  background-image: url("../../assets/img/BG-admin.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0 0;
  width: 100%;

  &__Content {
    height: 110vh;
  }
}

@media screen and (min-width: 1220px) {
  .adminLayoud {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    align-items: stretch;
    &__Content {
      height: 117vh;
      overflow-y: scroll;
      width: 85%;
    }
  }
}
