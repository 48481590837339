@import "../../styles/colors.scss";

.navigation {
  color: $withe-color;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 30px 3%;
  width: 100%;

  &__user {
    &Name {
      border-radius: 100%;
      border: 3px solid $blue-light-extra;
      height: 90px;
      margin-bottom: 10px;
      padding: 7px;
      text-transform: uppercase;
      width: 90px;
      a {
        text-decoration: none;
        div {
          align-items: center;
          border-radius: 100%;
          color: $withe-color;
          display: flex;
          font-size: 40px;
          font-weight: 700;
          height: 100%;
          justify-content: center;
          width: 100%;
          position: relative;
          .HomeIcon {
            font-size: 35px;
            position: absolute;
            top: 40px;
            left: 53px;
          }
        }
      }
    }
    h5,
    h4 {
      font-size: 20px;
      font-weight: 400;
      display: inline-block;
      margin: 0 10px 5px 0;
    }

    h3 {
      font-weight: 400;
    }
  }

  &__contents {
    display: none;
  }

  &__buttons {
    text-align: right;
    a {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: $withe-color;
      font-weight: 600;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &Icon {
      margin-left: 10px;
      font-size: 16px;
    }

    span {
      font-size: 10px;
    }
  }
}

@media screen and (min-width: 1220px) {
  .navigation {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    height: 100vh;
    padding: 0 20px;

    &__user {
      width: 100%;
      &Name {
        width: 120px;
        height: 120px;
        margin: 0 auto 20px;
        position: relative;
        a {
          div {
            .HomeIcon {
              font-size: 40px;
              position: absolute;
              top: 72px;
              left: 65px;
            }
          }
        }
      }
      h5,
      h4 {
        display: block;
        font-size: 30px;
        text-align: center;
      }
      h4 {
        font-weight: 600;
        margin-bottom: 30px;
      }
      p {
        display: none;
      }
      h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 40px;
        text-align: center;
      }
    }

    &__contents {
      border-bottom: 1px solid $withe-color;
      border-top: 1px solid $withe-color;
      display: block;
      margin-bottom: 40px;
      padding: 30px 0;
      width: 100%;
      h3 {
        margin-bottom: 25px;
      }
      div {
        align-items: center;
        background: linear-gradient(
          167.31deg,
          rgba(255, 255, 255, 0.64) -22.28%,
          rgba(255, 255, 255, 0.51) 40.15%,
          rgba(255, 255, 255, 0.23) 90.81%
        );
        backdrop-filter: blur(40px);
        border-radius: 10px;
        box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
        color: $withe-color;
        display: flex;
        flex-wrap: wrap;
        font-size: 60px;
        font-weight: 700;
        padding: 20px;

        p {
          font-size: 16px;
          font-weight: 700;
          margin-left: 10px;
        }
      }
    }

    &__buttons {
      text-align: left;
      a {
        flex-direction: row;
      }

      &Icon {
        margin: 0 10px 0 0;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .navigation {
    &__user {
      p {
        display: block;
        color: $withe-color;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 30px;
        text-align: center;
        text-decoration-line: underline;
      }
    }
    &__contents {
      div {
        font-size: 79px;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .navigation {
    width: 15%;
  }
}
