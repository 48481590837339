@import "../../styles/colors.scss";

.ModalDownload {
  background: linear-gradient(
    167.31deg,
    rgba(255, 255, 255, 0.94) -22.28%,
    rgba(255, 255, 255, 0.81) 40.15%,
    rgba(255, 255, 255, 0.53) 90.81%
  );
  border-radius: 20px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  left: 50%;
  outline: none;
  padding: 130px 180px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 900px;

  > h2 {
    border-bottom: 1px solid $gray-medium;
    color: $blue-dark-medium;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  label {
    color: $blue-dark-medium;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 15px;

    > textarea {
      background: $withe-color;
      border-radius: 5px;
      border: 1px solid #b9b9b9;
      height: 128px;
      outline: none;
      padding: 15px;
      resize: none;
      width: 100%;
    }
  }

  &__file {
    margin-bottom: 30px;
    > h3 {
      font-weight: 700;
      font-size: 15px;
      color: $blue-dark-medium;
      margin-bottom: 15px;
    }

    > input {
      display: none;
    }

    > label {
      flex-direction: row;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: $gray-txt-form;
    }
     &Icon{
        font-size: 22px;
        color: $blue-dark-medium;
        margin-right: 10px;
     }

  }

  &__buttons {
    display: flex;
    justify-content: center;
    > button {
      background: $blue-dark-medium;
      border-radius: 30px;
      border: none;
      color: $withe-color;
      cursor: pointer;
      font-size: 20px;
      margin-right: 15px;
      padding: 10px 80px;
      &:last-child {
        background-color: $withe-color;
        border: 1px solid $blue-dark-medium;
        color: $blue-dark-medium;
        margin: 0;
        padding: 10px 30px;
      }
    }
  }
}
