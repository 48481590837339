@import "../../styles/colors.scss";

.Adminheader {
  border-radius: 0px 0px 20px 20px;
  color: $withe-color;
  padding: 35px;
  position: relative;
  width: 100%;
  margin-top: 15px;
  h1 {
    border-bottom: 1px solid $withe-color;
    font-size: 35px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    width: 50%;
  }

  h6 {
    font-size: 14px;
    font-weight: 300;
  }

  figure {
    width: 200px;
    position: absolute;
    bottom: -20px;
    right: 10px;
    z-index: 899;
    img {
      width: 100%;
      pointer-events: none;
    }
  }
}

@media screen and (min-width: 1220px) {
  .Adminheader {
    border-radius: 20px;
  }
}
